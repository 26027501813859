.text-center {
  text-align: center;
}

.drawer-logo-mobile-wrapper{
  width: 12rem;
  margin-left: 10px;
  img {
    width: 100%;
  }
}

.hidden {
  display: none;
}

.cursor-pointer {
  cursor: pointer;
}

.table-action {
  white-space: nowrap;
}

.show-lg {
  display: none;
}

.hide-lg {
  display: block;
}

.w-100 {
  width: 100%;
}

.text-right {
  text-align: right;
}

.text-capitalize {
  text-transform: capitalize;
}

.select-filter {
  width: 100%;
}

@media only screen and (min-width: @screen-lg) {
  .show-lg {
    display: block;
  }
  .hide-lg {
    display: none;
  }
}

.ant-form-vertical .ant-form-item-label {
  padding-bottom: 2px;
}

.ant-page-header {
  padding: 0 0 1rem;
}

.ant-table-wrapper {
  overflow-x: auto;
}

.ant-card-body {
  .ant-form-item:last-child {
    margin-bottom: 0;
  }
}
